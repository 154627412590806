import {
  ClientData,
  ClientRequestData,
  ClientResponseData,
  ListClientResponseData,
} from './client.model';

import { environment } from 'src/environments/environment.development';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ClientService {
  private apiUrl = `${environment.apiUrl}/client`;

  constructor(private http: HttpClient) {}

  getAllClients(): Observable<ListClientResponseData> {
    return this.http.get<ListClientResponseData>(`${this.apiUrl}`);
  }

  getClient(id: string): Observable<ClientData> {
    return this.http.get<ClientData>(`${this.apiUrl}/${id}`);
  }

  getOrganizationClients(
    organizationId: string
  ): Observable<ListClientResponseData> {
    return this.http.get<ListClientResponseData>(
      `${this.apiUrl}?filters[organization_id]=${organizationId}`
    );
  }

  searchByName(search: string): Observable<ListClientResponseData> {
    return this.http.get<ListClientResponseData>(
      `${this.apiUrl}?search=${search}`
    );
  }

  createClient(data: ClientRequestData): Observable<ClientResponseData> {
    return this.http.post<ClientResponseData>(`${this.apiUrl}`, data);
  }

  updateClient(
    data: ClientRequestData,
    clientId: string
  ): Observable<ClientResponseData> {
    return this.http.patch<ClientResponseData>(
      `${this.apiUrl}/${clientId}`,
      data
    );
  }
}
