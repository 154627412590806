// src/app/guards/auth.guard.ts
import { inject, Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from '../services/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  authService = inject(AuthService);
  router = inject(Router);

  // canActivate(): boolean {
  //   if (this.authService.isAuthenticated()) {
  //     return true;
  //   }

  //   this.router.navigate(['/auth/login']);
  //   return false;
  // }

  // canActivate(route: ActivatedRouteSnapshot): boolean {
  //   // Check if user is authenticated
  //   if (!this.authService.isAuthenticated()) {
  //     this.router.navigate(['/auth/login']);
  //     return false;
  //   }

  //   // Get required role from route data
  //   const requiredRole = route.data['type'] as string;

  //   // If no role is required, grant access
  //   if (!requiredRole) {
  //     return true;
  //   }

  //   // Check if user has the required role
  //   if (this.authService.hasRole(requiredRole)) {
  //     return true;
  //   }

  //   // Redirect to unauthorized page if the role doesn't match
  //   this.router.navigate(['/unauthorized']);
  //   return false;
  // }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    if (!this.authService.isAuthenticated()) {
      this.router.navigate(['/auth/login']);
      return false;
    }

    const requiredRole = route.data['type'] as string;
    if (requiredRole && !this.authService.hasRole(requiredRole)) {
      this.router.navigate(['/unauthorized']);
      return false;
    }

    if (route.data.hasOwnProperty('hasClients')) {
      const hasClients = route.data['hasClients'] as boolean;
      if (this.authService.userHasClients() !== hasClients) {
        this.router.navigate(['/unauthorized']);
        return false;
      }
    }

    return true;
  }
}
