import { MessageService } from 'primeng/api';

import { Component, Input, OnInit, inject } from '@angular/core';
import {
  DuplicateRequest,
  Options,
} from 'src/app/services/client/client.model';
import { ClientService } from 'src/app/services/client/client.service';
import { RoleService } from 'src/app/services/role/role.service';
import { RoleIds } from 'src/app/services/role/role.model';

@Component({
  selector: 'app-duplicate-template-list',
  templateUrl: './duplicate-template-list.component.html',
  styleUrls: ['./duplicate-template-list.component.scss'],
  providers: [MessageService],
})
export class DuplicateTemplateListComponent implements OnInit {
  @Input({ required: true }) roleId?: string | null;

  clients: Options[] = [];
  selectedClient!: Options;

  roles: Options[] = [];
  selectedRole: Options | undefined;
  addedRole: Options[] = [];
  RoleIds: RoleIds[] = [];

  applyToClients: DuplicateRequest = {
    role_id: '',
    role: [],
  };

  errorMessage = '';

  clientService = inject(ClientService);
  roleService = inject(RoleService);
  messageService = inject(MessageService);

  ngOnInit(): void {
    this.getClients();
  }

  showBottomRight(detail: string, severity: string, summary: string) {
    this.messageService.add({
      severity: severity,
      summary: summary,
      detail: detail,
      key: 'br',
      life: 3000,
    });
  }

  getAllClients() {
    this.clientService.getAllClients().subscribe({
      next: (responseData) => {
        this.clients = [];
        responseData.data.map((data) => {
          this.clients.push({ name: data.name, code: data.id });
        });
      },
      error: (error) => {
        this.showBottomRight(`${error.error.message}`, 'error', 'Error');
      },
    });
  }

  getClientName(clientId: string) {
    const clientName = this.clients.find((client) => client.code === clientId);
    return clientName?.name;
  }

  onSelectClient() {
    if (this.selectedClient) {
      this.getRolesPerClient();
    } else {
      this.roles = [];
      if (this.selectedRole) {
        this.RoleIds.push({ id: this.selectedRole?.code });
      }
      console.log(this.RoleIds);
      this.selectedRole = undefined;
    }
  }

  onSelectRole() {
    if (this.selectedRole) {
      this.RoleIds.push({ id: this.selectedRole?.code });
    }
  }

  addClientRole() {
    if (!this.roleId) {
      this.showBottomRight(
        'Please select a role template to duplicate',
        'error',
        'Error'
      );
      return;
    }

    if (this.selectedRole) {
      if (!this.selectedRole.name || !this.selectedClient) {
        this.errorMessage = 'Client and role are required!';
        return;
      }

      if (this.roleId && this.selectedRole.name && this.selectedClient) {
        this.applyToClients.role_id = this.roleId;
        this.applyToClients.role.push({
          client_id: this.selectedClient.code,
          title: this.selectedRole.name,
        });

        console.log(this.RoleIds);
        this.addedRole.push(this.selectedRole);
        this.roles.splice(0, 1);
        this.selectedRole = undefined;
        this.errorMessage = '';
        return;
      }
    }
  }

  removeClientRole(clientRoleIndex: number) {
    if (!this.roles) {
      this.roles = [];
    }
    this.applyToClients.role_id = '';
    this.applyToClients.role.splice(clientRoleIndex, 1);
    this.roles.push(this.addedRole[clientRoleIndex]);
  }

  getClients() {
    this.clientService.getAllClients().subscribe({
      next: (response) => {
        this.clients = [];
        response.data.map((data) => {
          this.clients.push({ name: data.name, code: data.id });
        });
      },
      error: (error) => {
        this.showBottomRight(`${error.error.message}`, 'error', 'Error');
      },
    });
  }

  getRolesPerClient() {
    this.roleService.getRolesFilteredBy(this.selectedClient.code).subscribe({
      next: (response) => {
        this.roles = [];
        response?.data?.map((role) => {
          this.roles.push({
            name: role.title,
            code: role.id,
          });
        });
      },
      error: (error) => {
        this.showBottomRight(`${error.error.message}`, 'error', 'Error');
      },
    });
  }

  deleteRole(id: string) {
    this.roleService.deleteRole(id).subscribe({
      next: (response) => {},
      error: (error) => {
        this.showBottomRight(`${error.error.message}`, 'error', 'Error');
      },
    });
  }

  onDuplicateRole() {
    this.roleService.duplicateRole(this.applyToClients).subscribe({
      next: (response) => {
        this.RoleIds.map((role) => {
          this.deleteRole(role.id);
        });
        this.showBottomRight('Successfully Applied', 'success', 'Success');
      },
      error: (error) => {
        this.showBottomRight(`${error.error.message}`, 'error', 'Error');
      },
    });
  }
}
