<div cdkDrag>
  <div
    cdkDragHandle
    class="goal-header"
    [ngClass]="metric.toggle ? 'toggle-header-hidden' : ''"
    (mousedown)="onGetIndex()"
  >
    <div style="display: flex; align-items: center; justify-items: center">
      <p style="color: #fff; font-weight: 400; font-size: 14px">
        {{ metric.name }}
      </p>
    </div>

    <div style="display: flex; flex-direction: row; gap: 16px">
      <div style="display: flex; align-items: center; justify-items: center">
        <p style="color: #fff; font-weight: 400; font-size: 14px">
          {{ metric.weight }}%
        </p>
      </div>

      <div style="display: flex; align-items: center; justify-items: center">
        <i
          [ngClass]="metric.toggle ? 'rotated' : ''"
          class="pi pi-chevron-down"
          style="font-size: 1rem; color: #fff"
          (click)="onMetricToggle(metric)"
        ></i>
      </div>
      <div style="display: flex; align-items: center; justify-items: center">
        <p-menu #menu [model]="items" [popup]="true"> </p-menu>
        <i
          class="pi pi-ellipsis-v"
          style="font-size: 1rem; color: #fff"
          (click)="menu.toggle($event)"
        ></i>
      </div>
    </div>
  </div>

  <div
    class="goal-content"
    [ngClass]="metric.toggle ? 'toggle-content-hidden' : 'toggle-content-show'"
  >
    <div style="display: flex; flex-direction: row; gap: 10px">
      <div style="display: flex; flex-direction: column; width: 80%">
        <label>Metric</label>
        <input
          type="text"
          pInputText
          #metricName
          [value]="metric.name"
          [ngClass]="{ disabled: isReady }"
          (focusin)="checkValue(metricName.value)"
          (focusout)="
            updateMetric(
              metricName.value,
              metric.id,
              {
                name: metricName.value
              },
              kpiIndex,
              goalIndex,
              metricIndex,
              0
            )
          "
        />
      </div>
      <div style="display: flex; flex-direction: column; width: 20%">
        <label>Weight</label>
        <input
          type="text"
          pKeyFilter="int"
          pInputText
          #metricWeight
          [value]="metric.weight"
          [ngClass]="{ disabled: isReady }"
          (focusin)="checkValue(metricWeight.value)"
          (focusout)="
            updateMetric(
              metricWeight.value,
              metric.id,
              {
                weight: metricWeight.value
              },
              kpiIndex,
              goalIndex,
              metricIndex,
              0
            )
          "
        />
      </div>
    </div>

    <div style="display: flex; flex-direction: column">
      <label style="margin-top: 10px">Description</label>
      <textarea
        rows="5"
        cols="30"
        pInputTextarea
        style="flex-grow: 1"
        #metricDescription
        [value]="metric.description"
        [ngClass]="{ disabled: isReady }"
        (focusin)="checkValue(metricDescription.value)"
        (focusout)="
          updateMetric(
            metricDescription.value,
            metric.id,
            {
              description: metricDescription.value
            },
            kpiIndex,
            goalIndex,
            metricIndex,
            0
          )
        "
      ></textarea>
    </div>

    <div class="flex flex-column gap-3 mt-3">
      <label>Range Type</label>
      <div
        style="margin-top: -0.5px"
        class="flex flex-row gap-2"
        *ngFor="let rng of metric.range; index as rangeIndex"
      >
        <input
          #rngRange
          style="width: 8%; background: #0c64b8"
          class="disabled text-center text-white"
          [value]="rng.range"
          type="text"
          pInputText
        />
        <input
          #rngDescription
          style="width: 92%"
          type="text"
          pInputText
          [value]="rng.description"
          [ngClass]="{ disabled: isReady }"
          (focusin)="checkValue(rngDescription.value)"
          (focusout)="
            onUpdateRange(
              metric.id,
              rngDescription.value,
              kpiIndex,
              goalIndex,
              metricIndex,
              rangeIndex
            )
          "
        />
      </div>
    </div>
  </div>
</div>
<p-toast position="bottom-right" key="br" />
