import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { environment } from 'src/environments/environment.development';
import { LoginResponse } from './auth.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private isLoggedIn = new BehaviorSubject<boolean>(false);
  isLoggedIn$ = this.isLoggedIn.asObservable();

  constructor(private http: HttpClient) {
    const token = localStorage.getItem('token');
    this.isLoggedIn.next(!!token);
  }

  private userData: any = null;

  login(email: string, password: string): Observable<LoginResponse> {
    return this.http
      .post<LoginResponse>(`${environment.apiUrl}/auth/login`, {
        email,
        password,
      })
      .pipe(
        tap((response) => {
          localStorage.setItem('token', response.data.token);
          localStorage.setItem('id', response.data.id);
          localStorage.setItem('type', response.data.type);
          localStorage.setItem(
            'has_clients',
            response.data.organization.has_clients ? '1' : '0'
          );
          localStorage.setItem(
            'organization_id',
            response.data.organization.id
          );
          this.userData = response.data;
          localStorage.setItem('userData', JSON.stringify(this.userData));
          this.isLoggedIn.next(true);
        })
      );
  }

  // // Get user role
  // getRole(): string | null {
  //   return localStorage.getItem('type');
  // }

  // // Check if user has a required role
  // hasRole(requiredRole: string): boolean {
  //   return this.getRole() === requiredRole;
  // }

  logout(): Observable<any> {
    return this.http.post(`${environment.apiUrl}/auth/logout`, {}).pipe(
      tap(() => {
        this.userData = null;
        localStorage.removeItem('userData');
        localStorage.removeItem('token');
        this.isLoggedIn.next(false);
      })
    );
  }

  getToken(): string | null {
    return localStorage.getItem('token');
  }

  // isAuthenticated(): boolean {
  //   return !!this.getToken();
  // }

  // login(response: any) {
  //   this.userData = response.data;
  //   localStorage.setItem('userData', JSON.stringify(this.userData));
  // }

  isAuthenticated(): boolean {
    return !!localStorage.getItem('userData');
  }

  getRole(): string | null {
    const data = this.getUserData();
    return data ? data.type : null;
  }

  hasRole(role: string): boolean {
    return this.getRole() === role;
  }

  userHasClients(): boolean {
    const data = this.getUserData();
    return data?.organization?.has_clients ?? false;
  }

  getUserData(): any {
    if (!this.userData) {
      const storedData = localStorage.getItem('userData');
      this.userData = storedData ? JSON.parse(storedData) : null;
    }
    return this.userData;
  }

  // logout() {
  //   this.userData = null;
  //   localStorage.removeItem('userData');
  // }
}
