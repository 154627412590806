import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  EmployeeDataRequest,
  EmployeeResponseData,
  EmployeeResponseDataArr,
  EmployeeResponseDataId,
  GetEmployeeResponseData,
  GetEmployeesResponseData,
} from './employee.model';
import { environment } from 'src/environments/environment.development';

@Injectable({ providedIn: 'root' })
export class EmployeeService {
  private apiUrl = `${environment.apiUrl}/employee`;

  constructor(private http: HttpClient) {}

  getEmployees(organizationId?: string): Observable<EmployeeResponseDataArr> {
    if (organizationId) {
      return this.http.get<EmployeeResponseDataArr>(
        `${this.apiUrl}?filters[organization_id]=${organizationId}`
      );
    }
    return this.http.get<EmployeeResponseDataArr>(`${this.apiUrl}`);
  }

  getEmployee(id: string): Observable<GetEmployeeResponseData> {
    return this.http.get<GetEmployeeResponseData>(`${this.apiUrl}/${id}`);
  }

  searchByName(search: string): Observable<EmployeeResponseDataArr> {
    return this.http.get<EmployeeResponseDataArr>(
      `${this.apiUrl}?search=${search}`
    );
  }

  createEmployee(
    data: EmployeeDataRequest
  ): Observable<EmployeeResponseDataId> {
    return this.http.post<EmployeeResponseDataId>(`${this.apiUrl}`, data);
  }

  updateEmployee(
    data: EmployeeDataRequest,
    id: string
  ): Observable<EmployeeResponseDataId> {
    return this.http.patch<EmployeeResponseDataId>(
      `${this.apiUrl}/${id}`,
      data
    );
  }
}
