<p class="title">Apply to Clients</p>
<p class="description">
  Choose a client and specify the role for which you want to use this template.
</p>
<div class="container">
  <div class="header">
    <div>
      <label class="label">Client *</label>
      <p-dropdown
        [options]="clients"
        [(ngModel)]="selectedClient"
        optionLabel="name"
        [showClear]="true"
        placeholder="Select Client"
        (ngModelChange)="onSelectClient()"
      />
    </div>
    <div>
      <label class="label">Role *</label>
      <!-- <input style="width: 100%" type="text" pInputText [(ngModel)]="role" /> -->
      <p-dropdown
        [options]="roles"
        [(ngModel)]="selectedRole"
        optionLabel="name"
        [showClear]="true"
        placeholder="Select role"
        (ngModelChange)="onSelectRole()"
      />
    </div>

    <button style="align-self: flex-end" (click)="addClientRole()">Add</button>

    <p class="error">{{ errorMessage }}</p>
  </div>

  <div
    class="list-item"
    *ngFor="let role of applyToClients.role; index as clientRoleIndex"
  >
    <p>{{ getClientName(role.client_id) }}</p>
    <p>{{ role.title }}</p>
    <mat-icon svgIcon="remove" (click)="removeClientRole(clientRoleIndex)" />
  </div>

  <div class="no-data" *ngIf="!applyToClients.role.length">
    <p>No Data</p>
  </div>
</div>

<button
  style="width: 100px; float: right; margin-top: 10px"
  *ngIf="applyToClients.role.length"
  (click)="onDuplicateRole()"
>
  Apply
</button>

<p-toast position="bottom-right" key="br" />
