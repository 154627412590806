<div class="sidebar">
  <div class="sidebar-logo-container">
    <img
      class="sidebar-logo"
      src="assets/layout/images/sidebar/logo.svg"
      alt="Performance Eval Logo"
    />
  </div>
  <nav class="navigation">
    <a
      *ngIf="user_type === 'Organization Admin'"
      routerLink="/evaluation"
      routerLinkActive="active"
      class="nav-item"
    >
      <img
        src="assets/layout/images/sidebar/organisation.svg"
        alt="Evaluation"
      />
      <span>Evaluation</span>
    </a>

    <a
      *ngIf="user_type === 'Organization Admin'"
      routerLink="/employees"
      routerLinkActive="active"
      class="nav-item"
    >
      <img
        src="assets/layout/images/sidebar/organisation.svg"
        alt="Evaluation"
      />
      <span>Employees</span>
    </a>

    <a
      *ngIf="user_type === 'Organization Admin'"
      routerLink="/performance"
      routerLinkActive="active"
      class="nav-item"
    >
      <img src="assets/layout/images/sidebar/chart.svg" alt="Performance" />
      <span>Performance</span>
    </a>
    <a
      *ngIf="user_type === 'Organization Admin'"
      routerLink="/templates"
      routerLinkActive="active"
      class="nav-item"
    >
      <img src="assets/layout/images/sidebar/templates.svg" alt="Dashboard" />
      <span>Templates</span>
    </a>
    <a
      *ngIf="user_type === 'Super Admin'"
      routerLink="/organizations"
      routerLinkActive="active"
      class="nav-item"
    >
      <img
        src="assets/layout/images/sidebar/organisation.svg"
        alt="Organizations"
      />
      <span>Organizations</span>
    </a>
    <a
      *ngIf="user_type === 'Organization Admin' && has_client === '1'"
      routerLink="/clients"
      routerLinkActive="active"
      class="nav-item"
    >
      <img src="assets/layout/images/sidebar/organisation.svg" alt="Clients" />
      <span>Clients</span>
    </a>
    <a
      *ngIf="user_type === 'Organization Admin' && has_client === '0'"
      routerLink="/roles"
      routerLinkActive="active"
      class="nav-item"
    >
      <img src="assets/layout/images/sidebar/organisation.svg" alt="Roles" />
      <span>Roles</span>
    </a>
    <a
      *ngIf="user_type === 'Organization Admin'"
      routerLink="/users"
      routerLinkActive="active"
      class="nav-item"
    >
      <img src="assets/layout/images/sidebar/organisation.svg" alt="Users" />
      <span>Users</span>
    </a>
    <a
      *ngIf="user_type === 'Organization Admin'"
      routerLink="/settings"
      routerLinkActive="active"
      class="nav-item"
    >
      <img src="assets/layout/images/sidebar/settings.svg" alt="Settings" />
      <span>Settings</span>
    </a>
  </nav>
</div>
