import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { AppLayoutComponent } from './layout/app.layout.component';
import { NotfoundComponent } from './layout/notfound/notfound.component';
import { EvaluationFormComponent } from './modules/evaluation/evaluation-form/evaluation-form.component';
import { TemplateReadComponent } from './modules/evaluation/template-read/template-read.component';
import { TemplateCreateComponent } from './modules/templates/template-create/template-create.component';
import { AuthGuard } from './guards/auth.guard';

@NgModule({
  imports: [
    RouterModule.forRoot(
      [
        {
          path: 'auth/login',
          loadChildren: () =>
            import('./modules/auth/auth.module').then((m) => m.AuthModule),
        },
        {
          path: '',
          component: AppLayoutComponent,
          canActivate: [AuthGuard],
          children: [
            {
              path: '',
              redirectTo: '/evaluation',
              pathMatch: 'full',
            },
            {
              path: 'evaluation',
              loadChildren: () =>
                import('./modules/evaluation/evaluation.module').then(
                  (m) => m.EvaluationModule
                ),
            },
            {
              path: 'evaluation/employee/:id',
              component: EvaluationFormComponent,
            },
            {
              path: 'performance',
              loadChildren: () =>
                import('./modules/performance/performance.module').then(
                  (m) => m.PerformanceModule
                ),
            },
            {
              path: 'templates',
              redirectTo: 'templates',
            },
            {
              path: 'templates/roles/:id',
              component: TemplateReadComponent,
            },
            {
              path: 'templates/roles/:id/edit',
              component: TemplateCreateComponent,
            },
            {
              path: 'templates',
              loadChildren: () =>
                import('./modules/templates/templates.module').then(
                  (m) => m.TemplatesModule
                ),
            },
            {
              path: 'employees',
              loadChildren: () =>
                import('./modules/employees/employees.module').then(
                  (m) => m.EmployeesModule
                ),
            },
            {
              path: 'organizations',
              loadChildren: () =>
                import('./modules/organizations/organizations.module').then(
                  (m) => m.OrganizationsModule
                ),
            },
            {
              path: 'clients',
              redirectTo: 'clients',
            },
            {
              path: 'clients',
              loadChildren: () =>
                import('./modules/clients/clients.module').then(
                  (m) => m.ClientsModule
                ),
            },
            {
              path: 'roles',
              loadChildren: () =>
                import('./modules/roles/roles.module').then(
                  (m) => m.RolesModule
                ),
            },
            {
              path: 'users',
              loadChildren: () =>
                import('./modules/users/users.module').then(
                  (m) => m.UsersModule
                ),
            },
          ],
        },
        { path: 'notfound', component: NotfoundComponent },
        { path: '**', redirectTo: '/notfound' },
      ],
      {
        scrollPositionRestoration: 'enabled',
        anchorScrolling: 'enabled',
        onSameUrlNavigation: 'reload',
      }
    ),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
